<template>
  <section>
    <div
      class="datepicker"
      :class="{'form-group': !disableFormGroup, 'has-danger': error}"
      :data-test="dataAttr ? dataAttr : id"
    >
      <label v-if="!noLabel" :for="id">
        <span class="datepicker__label">{{ label }}</span>
        <Tooltip
          v-if="toolTipText.length"
          :title="toolTipText"
          customInfoClass="seo"
        />
      </label>
      <div class="datepicker__input-group input-group" :class="{'datepicker__flatpickr--enabled': !disabled}">
        <FlatPickr
          :value="value"
          :placeholder="placeholder"
          :config="config"
          :disabled="disabled"
          @input="updateDate"
          class="datepicker__flatpickr"
        />
        <div class="datepicker__calendar">
          <IconCalendar class="datepicker__icon-calendar" />
        </div>
      </div>
    </div>
    <div
      v-if="showSetNow"
      class="datepicker__set-now"
      @click="setDateToNow"
    >
      {{ $t('buttons.set_to_now') }}
    </div>
  </section>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import WeekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import { Slovak } from 'flatpickr/dist/l10n/sk.js'
import { Czech } from 'flatpickr/dist/l10n/cs.js'
import { Default } from 'flatpickr/dist/l10n/default'
import Tooltip from '../tooltip/Tooltip'
import IconCalendar from '@/assets/img/svg/calendar.svg?inline'

export default {
  name: 'Datepicker',
  props: {
    value: {
      type: [String, Date],
      default: ''
    },
    type: {
      type: String,
      default: 'datetime'
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disableFormGroup: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    enable: {
      type: Array,
      default: () => []
    },
    weekNumbers: {
      type: Boolean,
      default: false
    },
    customDateFormat: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'single'
    },
    showMonths: {
      type: Number,
      default: 1
    },
    toolTipText: {
      type: String,
      default: ''
    },
    showSetNow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    },
    minDate: {
      type: Date,
      required: false
    },
    maxDate: {
      type: Date,
      required: false
    }
  },
  components: {
    FlatPickr,
    Tooltip,
    IconCalendar
  },
  computed: {
    altFormat () {
      let format = 'd.m.Y H:i'
      if (this.type === 'date') {
        format = 'd.m.Y'
      }
      if (this.type === 'month') {
        format = 'm.Y'
      }
      if (this.type === 'week') {
        format = 'W: d.m.Y'
      }
      if (this.type === 'time') {
        format = 'H:i'
      }
      if (this.value === '1900-01-01T00:00:00.000000Z') {
        format = ''
      }
      if (this.value === '2100-01-01T00:00:00.000000Z') {
        format = ''
      }
      return format
    },
    dateFormat () {
      let format = 'Z'
      if (this.type === 'month') {
        format = 'Y-m'
      }
      if (this.type === 'time') {
        format = 'H:i'
      }
      if (this.customDateFormat !== '') {
        format = this.customDateFormat
      }
      return format
    },
    enableTime () {
      let enableTime = true
      if (this.type === 'date') {
        enableTime = false
      }
      return enableTime
    },
    noCalendar () {
      let noCalendar = false
      if (this.type === 'time') {
        noCalendar = true
      }
      return noCalendar
    },
    plugins () {
      const plugins = []
      if (this.type === 'month') {
        plugins.push(new MonthSelectPlugin({ shorthand: true, dateFormat: 'Y-m', altFormat: 'm.Y' }))
      }
      if (this.type === 'week') {
        plugins.push(new WeekSelectPlugin())
      }
      return plugins
    },
    config () {
      const locale = this.$i18n.locale === 'cz' ? Czech
        : this.$i18n.locale === 'sk' ? Slovak
          : Default
      const config = {
        enableTime: this.enableTime,
        time_24hr: true,
        altFormat: this.altFormat,
        dateFormat: this.dateFormat,
        altInput: true,
        allowInput: false,
        disableMobile: true,
        plugins: this.plugins,
        weekNumbers: this.weekNumbers,
        noCalendar: this.noCalendar,
        showMonths: this.showMonths,
        mode: this.mode,
        minDate: this.minDate,
        maxDate: this.maxDate,
        locale: locale
      }
      if (this.enable.length > 0) {
        config.enable = this.enable
      }
      return config
    }
  },
  methods: {
    updateDate (value) {
      this.$emit('input', value)
      this.$emit('change')
    },
    changeDate (event) {
      this.$emit('input', event.date)
      this.$emit('change')
    },
    checkMinAndMaxDate () {
      if (this.value === '1900-01-01T00:00:00.000000Z') {
        this.updateDate(new Date())
      }
      if (this.value === '2100-01-01T00:00:00.000000Z') {
        this.updateDate(new Date())
      }
    },
    setDateToNow () {
      this.updateDate(new Date())
    }
  }
}
</script>

<style lang="scss">
  .datepicker {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    padding: 0;
    &__label {
      color: #8A96AC;
    }
    &__flatpickr {
      border-radius: rem(6px) !important;
      &--enabled input {
        background: #fff !important;
        color: #000 !important;
        font-weight: 700;
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid #ced4da;
      }
    }
    &__input-group {
      height: rem(40px);
    }
    &__calendar {
      color: #7390C8;
      background: #E3ECFD;
      border-radius: rem(6px);
      width: rem(32px);
      height: rem(32px);
      position: absolute;
      right: rem(4px);
      top: rem(4px);
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }
    &__icon-calendar {
      width: rem(14px);
    }
    &__set-now {
      cursor: pointer;
      text-decoration: underline;
      text-align: right;
      margin-top: -1rem;
      font-size: rem(12px);
      color: #6599FE;
    }
  }
</style>
